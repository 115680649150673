import { Image } from "@runners/ui"
import AdvertisementMark from "../../components/advertisement-mark"

interface StoryDetailCardBannerProps {
  title: string
  description: string
  thumbnail_image_url: string
  is_advertisement?: boolean

  link: string
}

const StoryDetailCardBanner = (props: StoryDetailCardBannerProps) => {
  const { title, description, thumbnail_image_url, is_advertisement, link } = props
  return (
    <a href={link}>
      <div className="relative flex h-[160px] w-[659px] items-center rounded-2">
        <div className="p-[34px]">
          <p className="text-16 text-gray-900">{title}</p>
          <div dangerouslySetInnerHTML={{ __html: description }} className="text-20 font-bold" />
        </div>
        <div className="absolute top-0 -z-10 h-[160px] w-[659px]">
          {is_advertisement && <AdvertisementMark className="m-5" />}
          <Image size="2560" className="size-full object-fill" src={thumbnail_image_url} />
        </div>
      </div>
    </a>
  )
}

export default StoryDetailCardBanner

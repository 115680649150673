import { Controller, useFormContext } from "react-hook-form"
import ReactSelect from "react-select"
import { Input } from "@runners/ui"
import AdminFormField from "@/_components/admin-form-field"
import ActiveControl from "@/_components/react-hook-form/active-control"
import { BANNER_DISPLAY_TYPE, BANNER_TYPE } from "../_constants"
import AdminBannerDisplayFields from "./admin-banner-display-fields"

type ItemProps = {
  label: string
  value: string
}

export type AdminBannerFormType = {
  thumbnail_image_url: string
  mobile_thumbnail_image_url: string
  type: ItemProps
  display_type: ItemProps
  link: string
  order: string // payload number
  is_active: boolean
}

const BannerForm = () => {
  const { register, control } = useFormContext()

  return (
    <div className="max-w-[1200px]">
      <div className="flex flex-col gap-4">
        <AdminFormField label="유형" required>
          <Controller
            name="type"
            render={({ field }) => <ReactSelect {...field} className="min-w-[400px]" options={BANNER_TYPE} />}
          />
        </AdminFormField>

        <AdminFormField label="적용화면" required>
          <Controller
            name="display_type"
            render={({ field }) => <ReactSelect {...field} className="min-w-[400px]" options={BANNER_DISPLAY_TYPE} />}
          />
        </AdminFormField>

        <AdminFormField label="링크">
          <Input
            {...register("link")}
            placeholder="https://sense.im?utm_source=instagram&utm_medium=cpc&utm_campaign=오프라인_A&utm_content=인입률_AB_TEST&utm_trk=1"
            className="min-w-[400px]"
          />
        </AdminFormField>

        <AdminFormField label="우선도">
          <Input type="number" {...register("order")} placeholder="9999" className="min-w-[400px]" />
        </AdminFormField>

        <div className="h-6" />
        <ActiveControl name="is_active" />
        <ActiveControl name="is_advertisement" label="광고 여부" />
      </div>

      <div className="h-10" />

      <AdminBannerDisplayFields />

      <div className="h-10" />
    </div>
  )
}

export default BannerForm

import { useFormContext } from "react-hook-form"
import { Button, Input } from "@runners/ui"
import AdminFormField from "@/_components/admin-form-field"
import BannerEditor from "@/_components/editorjs/banner-editor"
import usePreviewModal from "@/_pages/service/banner/_hooks/use-preview-modal"
import StoryDetailCardBanner from "../../../preview/story/detail/story-detail-card-banner"
import ShouldUnregisterThumbnailInput from "../../../should-unregister-thumbnail-input"

const AdminStoryDetailCardFields = () => {
  const { register } = useFormContext()
  const { openModal } = usePreviewModal({
    Component: StoryDetailCardBanner,
    key: "admin-story-detail-card-preview",
  })
  return (
    <>
      <AdminFormField label="타이틀">
        <Input {...register("title")} className="min-w-[400px]" />
      </AdminFormField>
      <BannerEditor />
      <ShouldUnregisterThumbnailInput name="thumbnail_image_url" label="웹 이미지" className="h-[160px] w-[659px]" />
      <div>
        <Button size="48" onClick={openModal}>
          미리보기
        </Button>
      </div>
    </>
  )
}

export default AdminStoryDetailCardFields

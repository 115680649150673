import { Image } from "@runners/ui"
import AdvertisementMark from "../../components/advertisement-mark"

interface ProductDetailCardTypeBannerProps {
  title: string
  description: string

  thumbnail_image_url: string
  mobile_thumbnail_image_url: string

  is_advertisement: string

  link: string

  isMobile?: boolean
}

const ProductDetailCardTypeBanner = (props: ProductDetailCardTypeBannerProps) => {
  const { link, description, is_advertisement, mobile_thumbnail_image_url, thumbnail_image_url, title, isMobile } =
    props

  return (
    <a href={link}>
      <div className="relative h-[88px] w-[335px] overflow-hidden rounded-1 md:h-[118px] md:w-[464px]">
        <div className="p-5 pr-4">
          <p className="whitespace-pre-wrap text-16 font-bold text-gray-900">{title}</p>
          <div className="mt-3" />
          <p className="hidden whitespace-pre-wrap text-12 font-medium text-gray-700 md:block ">{description}</p>
        </div>
        <div className="absolute top-0 -z-10 size-full">
          {is_advertisement && <AdvertisementMark className="m-5" />}
          <Image
            size="2560"
            className="size-full object-cover"
            src={isMobile ? mobile_thumbnail_image_url : thumbnail_image_url}
          />
        </div>
      </div>
    </a>
  )
}

export default ProductDetailCardTypeBanner

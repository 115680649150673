import { Button, Image } from "@runners/ui"
import AdvertisementMark from "../components/advertisement-mark"

interface HomeButtonTypeBannerProps {
  title: string
  description: string
  button_title: string
  button_title_color: string
  button_background_color: string

  link: string

  thumbnail_image_url: string
  mobile_thumbnail_image_url: string

  is_advertisement?: boolean

  isMobile?: boolean
}

const HomeButtonTypeBanner = (props: HomeButtonTypeBannerProps) => {
  const {
    button_background_color,
    button_title,
    button_title_color,
    description,
    link,
    thumbnail_image_url,
    title,
    is_advertisement,
    mobile_thumbnail_image_url,
    isMobile,
  } = props

  return (
    <div className="relative h-[230px] w-[335px] overflow-hidden rounded-2 md:h-[240px] md:w-[1024px]">
      <div className="h-[164px] w-[275px] py-7 pl-[30px] md:h-[164px] md:w-[571px]">
        <p className="whitespace-pre-wrap text-20 font-bold leading-[26px] text-gray-900">{title}</p>
        <div className="mt-3" />
        <p className="whitespace-pre-wrap text-12 text-gray-600 md:text-14">{description}</p>
        <div className="mt-5" />
        <a href={link}>
          <Button
            size="40"
            style={{ backgroundColor: button_background_color, color: button_title_color }}
            className="rounded-full"
          >
            {button_title}
          </Button>
        </a>
      </div>
      <div className="absolute top-0 -z-10 size-full">
        {is_advertisement && <AdvertisementMark className="m-5" />}
        <Image
          size="2560"
          className="size-full object-cover"
          src={isMobile ? mobile_thumbnail_image_url : thumbnail_image_url}
        />
      </div>
    </div>
  )
}

export default HomeButtonTypeBanner

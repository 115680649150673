import { useFormContext } from "react-hook-form"
import { AdminBannerEditor } from "@runners/editor"
const BannerEditor = () => {
  const {
    setValue,
    register,
    formState: { defaultValues },
  } = useFormContext()

  const onChangeEditor = (content: string) => {
    setValue("description", content)
  }

  return (
    <>
      <AdminBannerEditor initialValues={defaultValues?.description ?? ""} onChangeEditor={onChangeEditor} />
      {/* shouldUnregister를 위한 더미 input */}
      <input {...register("description")} className="hidden" />
    </>
  )
}

export default BannerEditor

import { DjangoSearchOrderingParams, DjangoListResponse } from "@runners/interface"
import api, { METHOD } from "."
import { SettlementGroupDto, SettlementStatus } from "./settlement.type"

const settlementApi = {
  /**
   * 정산 그룹 리스트 조회
   */
  getSettlementGroupList: async (params: DjangoSearchOrderingParams) => {
    const { data } = await api<DjangoListResponse<SettlementGroupDto>>(`/admin/settlement-groups`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 정산 그룹 수정
   */
  patchSettlementGroup: async ({
    settlementGroupId,
    payload,
  }: {
    settlementGroupId: number
    payload: { status: SettlementStatus }
  }) => {
    const { data } = await api(`/admin/settlement-group/${settlementGroupId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },
}

export default settlementApi

import ShouldUnregisterThumbnailInput from "../should-unregister-thumbnail-input"

const AdminThumbnailFields = () => {
  return (
    <>
      <ShouldUnregisterThumbnailInput name="thumbnail_image_url" label="웹 이미지" className="w-[1280px]" />
      <ShouldUnregisterThumbnailInput name="mobile_thumbnail_image_url" label="모바일 이미지" />
    </>
  )
}

export default AdminThumbnailFields

import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import storeQueryKey from "@/_api/_query-key/store"
import { StoreListDto } from "@/_api/store.type"
import userApi from "@/_api/user"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableActivateCurry } from "@/_components/table/table-activate"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { useTableSearch } from "@/_hooks/use-table-search"
import { useGetStoreListQuery } from "./_hooks/use-get-query"
import { useActivateSellerMutation, useDeactivateSellerMutation } from "./_hooks/use-mutation"

interface SellerPageProps {}

const SellerPage = (props: SellerPageProps) => {
  const {} = props

  const { Table, page, pageSize } = usePaginationTable<StoreListDto>()

  const { SearchInput, search } = useTableSearch()

  const {
    data: storeList,
    isLoading,
    totalCount,
  } = useGetStoreListQuery({
    page_size: pageSize,
    page,
    search,
  })

  /**
   * 선택 드롭다운
   */
  const qc = useQueryClient()
  const { mutate: activate } = useActivateSellerMutation({
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: storeQueryKey.storeList({}) })
    },
    onError: (e: any) => {
      console.error(e)
      alert("error!")
    },
  })
  const { mutate: deactivate } = useDeactivateSellerMutation({
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: storeQueryKey.storeList({}) })
    },
    onError: (e: any) => {
      console.error(e)
      alert("error!")
    },
  })

  const tableDropdownList: TableDropdownList = [
    {
      fn: store_ids => {
        activate({ store_ids })
      },
      label: "선택 활성화",
    },
    {
      fn: store_ids => {
        deactivate({ store_ids })
      },
      label: "선택 비활성화",
    },
  ]

  return (
    <AdminPageLayout totalCount={totalCount}>
      <SearchInput
        className="max-w-[500px]"
        label="검색 필터"
        description="가능한 필드: 상호명"
        placeholder="필터링할 검색어 입력"
      />
      <Table
        isLoading={isLoading}
        totalCount={totalCount}
        data={storeList}
        activeCheck
        tableDropdownList={tableDropdownList}
        href={value => {
          return `/store/seller/${value?.id}`
        }}
        className="min-w-[1600px]"
        title={[
          "ID",
          "상태",
          "상호명",
          "업태",
          "스토어 이동",
          "종목",
          "대표",
          "담당자",
          "담당자 이메일",
          "담당자 전화번호",

          "등록한 상품",
          "총 판매량",
          "생성일",
        ]}
        widths={[2, 2, 3, 3, 2, 3, 2, 2, 4, 4, 2, 2, 2]}
        values={{
          ID: value => {
            return value?.id
          },
          상태: TableActivateCurry(),
          상호명: value => value?.title,
          업태: value => value?.industry_type,
          "스토어 이동": value => {
            return (
              <div
                className="cursor-pointer text-blue-600 underline underline-offset-4 hover:text-blue-800"
                onClick={async e => {
                  e.preventDefault()
                  try {
                    e.stopPropagation()
                    await userApi.patchAdminUserMe({ store: value?.id })
                    window.open(`${import.meta.env.VITE_SENSE_STORE_URL}/dashboard`)
                  } catch (e) {
                    console.error(`e`, e)
                    alert("썸띵 롱")
                  }
                }}
              >
                이동
              </div>
            )
          },
          종목: value => value?.sub_industry_type,
          대표: value => value?.owner_name,
          담당자: value => value?.owner_name,
          "담당자 이메일": value => value?.assigner_email,
          "담당자 전화번호": value => value?.assigner_phone,
          "등록한 상품": value => value?.product_count,
          "총 판매량": value => value?.product_sold_count,
          생성일: value => {
            return dayjs(value?.created).format("YY.MM.DD")
          },
        }}
      />
    </AdminPageLayout>
  )
}

export default SellerPage

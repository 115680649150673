import { Image } from "@runners/ui"
import AdvertisementMark from "../../../preview/components/advertisement-mark"

interface ContentDetailBandTypeBannerProps {
  description: string
  thumbnail_image_url: string
  is_advertisement?: boolean
  mobile_thumbnail_image_url: string

  isMobile: boolean

  link: string
}
const ContentDetailBandTypeBanner = (props: ContentDetailBandTypeBannerProps) => {
  const { description, link, thumbnail_image_url, isMobile, is_advertisement, mobile_thumbnail_image_url } = props
  return (
    <a href={link}>
      <div className="relative h-[84px] w-[335px] overflow-hidden rounded-2 md:h-[120px] md:w-[1024px]">
        <div className="absolute z-10 px-6 py-5 md:px-[120px] md:py-8">
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className="text-14 text-gray-500 md:text-16 [&>p>span>strong]:text-16 md:[&>p>span>strong]:text-20 [&>p>strong]:text-16 [&>p>strong]:text-gray-900 md:[&>p>strong]:text-20"
          />
        </div>
        <div className="absolute top-0 size-full">
          {is_advertisement && <AdvertisementMark className="mr-4 mt-3.5" />}
          <Image
            size="2560"
            className="size-full object-fill"
            src={isMobile ? mobile_thumbnail_image_url : thumbnail_image_url}
          />
        </div>
      </div>
    </a>
  )
}

export default ContentDetailBandTypeBanner

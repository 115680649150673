import { useRef, useState } from "react"
import { LinkPreviewDto } from "@runners/interface"
import { Button, Input } from "@runners/ui"
import withModalStackProps from "@/_hoc/with-modal-stack-props"
import { useGetLinkPreviewQuery } from "@/_pages/service/post/_hooks/use-get-query"

interface AddLinkModalProps {
  onSubmit: (data: LinkPreviewDto) => void
}

const AddLinkModal = withModalStackProps<AddLinkModalProps>(props => {
  const { close, onSubmit } = props

  const [url, setUrl] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)

  const { data } = useGetLinkPreviewQuery({ url })

  const handleSubmit = () => {
    if (data) {
      onSubmit(data)
    }
    close()
  }

  return (
    <div className="flex w-96 flex-col gap-4 bg-white p-4">
      <div className="text-18 font-bold">링크 추가</div>

      <div className="flex gap-2">
        <Input className="flex-1" ref={inputRef} />
        <Button type="button" size="36" onClick={() => setUrl(inputRef.current?.value || "")}>
          찾기
        </Button>
      </div>

      <div>
        {data ? (
          <div className="border border-gray-300">
            <div className="h-40 w-full bg-gray-300">
              {data.image && <img src={data.image} alt={data.title} className="size-full object-cover" />}
            </div>
            <div className="flex flex-col gap-2 p-4 ">
              <div className="line-clamp-2 text-16 font-bold">{data.title}</div>
              <div className="line-clamp-2 text-14">{data.description}</div>
              <div className="text-14 text-gray-500">{data.url}</div>
            </div>
          </div>
        ) : (
          <div className="text-14 text-gray-500">링크 정보 없음</div>
        )}
      </div>

      <div className="flex justify-end gap-2">
        <Button type="button" onClick={close} color="gray" size="48" className="w-24">
          취소
        </Button>
        <Button type="button" onClick={handleSubmit} size="48" disabled={!data} className="w-24">
          적용
        </Button>
      </div>
    </div>
  )
})

export default AddLinkModal

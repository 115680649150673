import { Button, Image } from "@runners/ui"
import AdvertisementMark from "../components/advertisement-mark"

interface StoryCardTopBannerProps {
  title: string
  description: string
  button_title?: string
  button_title_color: string
  button_background_color: string

  link: string

  thumbnail_image_url: string

  is_advertisement?: boolean
}

const StoryCardTopBanner = (props: StoryCardTopBannerProps) => {
  const { button_background_color, button_title, button_title_color, description, link, thumbnail_image_url, title } =
    props
  return (
    <a href={link}>
      <div className="relative h-[230px] w-[325px] rounded-1">
        <div className="pl-7 pt-[30px]">
          <p className="whitespace-pre-wrap text-14 font-medium text-gray-700">{title}</p>
          <div className="mt-1.5" />
          <p className="whitespace-pre-wrap text-20 font-bold text-gray-900">{description}</p>
          <div className="mt-3.5" />
          {button_title && (
            <a href={link}>
              <Button
                size="40"
                style={{ backgroundColor: button_background_color, color: button_title_color }}
                className="rounded-full"
              >
                {button_title}
              </Button>
            </a>
          )}
        </div>
        <div className="absolute top-0 -z-10 size-full">
          {props.is_advertisement && <AdvertisementMark className="m-5" />}
          <Image size="2560" className="size-full object-fill" src={thumbnail_image_url} />
        </div>
      </div>
    </a>
  )
}

export default StoryCardTopBanner

import { Badge } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import labelQueryKey from "@/_api/_query-key/label"
import { AdminLabelListDto, AdminLabelListParams } from "@/_api/label.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { useTableSearch } from "@/_hooks/use-table-search"
import { useGetLabelList } from "./_hooks/use-get-query"
import {
  useActivateLabelListMutation,
  useDeactivateLabelListMutation,
  useDeleteLabelListMutation,
} from "./_hooks/use-mutation"

const LabelPage = () => {
  const { page, Table, pageSize } = usePaginationTable<AdminLabelListDto>()
  const { SearchInput, search } = useTableSearch()

  const params: AdminLabelListParams = {
    page,
    page_size: pageSize,
    search,
  }
  const { data: labelList, totalCount, isLoading } = useGetLabelList(params)

  const qc = useQueryClient()

  const onSuccess = () => {
    qc.invalidateQueries({ queryKey: labelQueryKey.labelList({}) })
  }
  const onError = (e: any) => {
    console.error(e)
    alert("error!")
  }

  const { mutate: activate } = useActivateLabelListMutation({
    onSuccess,
    onError,
  })
  const { mutate: deactivate } = useDeactivateLabelListMutation({
    onSuccess,
    onError,
  })

  const { mutate: deleteLabels } = useDeleteLabelListMutation({
    onSuccess,
    onError,
  })

  /**
   * 드롭다운
   */
  const tableDropdownList: TableDropdownList = [
    {
      fn: labelId => {
        activate({ label_ids: labelId })
      },
      label: "선택 활성화",
    },
    {
      fn: labelId => {
        deactivate({ label_ids: labelId })
      },
      label: "선택 비활성화",
    },
    {
      fn: labelId => {
        deleteLabels({ label_ids: labelId })
      },
      label: "선택 삭제",
    },
  ]

  return (
    <div className="">
      <AdminPageLayout totalCount={totalCount} create>
        <SearchInput
          className="max-w-[500px]"
          label="검색 필터"
          description="가능한 필드: 제목"
          placeholder="검색어 입력"
        />
        <p className="text-14">
          현재 검색어: <span className="font-bold">{search}</span>
        </p>
        <Table
          totalCount={totalCount}
          tableDropdownList={tableDropdownList}
          isLoading={isLoading}
          data={labelList}
          className="min-w-[1200px]"
          title={["ID", "상태", "제목", "작성자", "게시글 수", "생성일", "마지막 수정일"]}
          activeCheck
          href={value => `/service/label/${value?.id}`}
          widths={[2, 2, 3, 3, 2, 3, 3]}
          values={{
            ID: value => {
              return value?.id
            },
            상태: value => {
              return (
                <div className="flex w-full justify-center">
                  {value?.is_active ? <Badge color="primary">활성화</Badge> : <Badge color="gray">비활성화</Badge>}
                </div>
              )
            },
            제목: value => {
              return value?.title
            },
            작성자: value => {
              return value.user.username || "-"
            },
            "게시글 수": value => {
              return value?.post_count
            },
            생성일: value => {
              return dayjs(value?.created).format("YY.MM.DD")
            },
            "마지막 수정일": value => {
              return dayjs(value?.modified).format("YY.MM.DD")
            },
          }}
        />

        <div className="h-10" />
      </AdminPageLayout>
    </div>
  )
}

export default LabelPage

import React from "react"

interface FilterDropdownTableProps {
  filterOption: string
  setFilterOption: (value: string) => void
}

const FilterDropdownTable = ({ filterOption, setFilterOption }: FilterDropdownTableProps) => {
  const handleSelectOption = (option: string) => {
    setFilterOption(option)
  }
  return (
    <select
      value={filterOption}
      onChange={e => handleSelectOption(e.target.value)}
      className="mx-10 my-4 rounded border p-2"
    >
      <option value="전체보기">전체보기</option>
      <option value="스태프만 보기">스태프만 보기</option>
      <option value="유저만 보기">유저만 보기</option>
    </select>
  )
}

export default FilterDropdownTable

import { Button } from "@runners/ui"
import usePreviewModal from "@/_pages/service/banner/_hooks/use-preview-modal"
import ProductDetailCardTypeBanner from "../../../preview/product/detail/product-detail-card-type-banner"
import ShouldUnregisterThumbnailInput from "../../../should-unregister-thumbnail-input"
import AdminHomeDefaultFields from "../../home/admin-home-default-fields"

const AdminProductDetailCardFields = () => {
  const { openModal } = usePreviewModal({
    Component: ProductDetailCardTypeBanner,
    key: "admin-product-detail-card-preview",
  })
  return (
    <>
      <AdminHomeDefaultFields />
      <ShouldUnregisterThumbnailInput
        name="thumbnail_image_url"
        label="웹 이미지"
        key="web-image"
        className="h-[118px] w-[464px]"
      />
      <ShouldUnregisterThumbnailInput
        name="mobile_thumbnail_image_url"
        label="모바일 이미지"
        key="web-image"
        className="h-[88px] w-[335px]"
      />
      <div>
        <Button size="40" onClick={openModal}>
          미리보기
        </Button>
      </div>
    </>
  )
}

export default AdminProductDetailCardFields

import { DjangoListResponse, DjangoResponse } from "@runners/interface"
import { statusOptionList } from "@runners/lib/constants"
import api, { METHOD } from "."
import {
  AdminProductDto,
  AdminProductListDto,
  AdminProductListParams,
  AdminProductOptionGroupListDto,
  AdminProductOptionListDto,
} from "./product.type"

const productApi = {
  /**
   * 상품 삭제
   */
  deleteProduct: async ({ productId }: { productId: number }) => {
    const { data } = await api(`/admin/product/${productId}`, {
      method: METHOD.DELETE,
    })
    return data
  },
  /**
   * 상품 선택 삭제
   */
  deleteProductList: async ({ product_ids }: { product_ids: number[] }) => {
    const { data } = await api(`/admin/products`, {
      method: METHOD.DELETE,
      params: { product_ids },
    })
    return data
  },

  /**
   * 상품 객체 조회
   */
  getProduct: async ({ productId }: { productId: number }) => {
    const { data } = await api<DjangoResponse<AdminProductDto>>(`/admin/product/${productId}`, {
      method: METHOD.GET,
    })
    return data
  },

  /**
   * 어드민 옵션 리스트 조회
   */
  getProductOptionGroupList: async ({ productId }: { productId: number }) => {
    const { data } = await api<DjangoListResponse<AdminProductOptionGroupListDto>>(
      `/admin/product/${productId}/option-groups`,
      {
        method: METHOD.GET,
      },
    )
    return data
  },

  /**
   * 어드민 상품 옵션 리스트 조회
   */
  getProductOptionList: async ({ productId }: { productId: number }) => {
    const { data } = await api<DjangoListResponse<AdminProductOptionListDto>>(`/admin/product/${productId}/options`, {
      method: METHOD.GET,
    })
    return data
  },

  /**
   * 상품 리스트 조회
   */
  getProductList: async (params: AdminProductListParams) => {
    const { data } = await api<DjangoListResponse<AdminProductListDto>>(`/admin/products`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 상품 수정
   *
   * @comment 현재 사용 안함
   */
  patchProduct: async ({ productId, payload }: { productId: number; payload: unknown }) => {
    const { data } = await api(`/admin/product/${productId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },

  /**
   * 상품 생성
   * @comment 현재 사용 안함
   */
  postProduct: async (payload: unknown) => {
    const { data } = await api(`/admin/product`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 상품 선택 활성화
   */
  postProductActivate: async ({ product_ids }: { product_ids: number[] }) => {
    const { data } = await api(`/admin/product/activate`, {
      method: METHOD.POST,
      data: { product_ids },
    })
    return data
  },

  /**
   * 상품 선택 비활성화
   */
  postProductDeactivate: async ({ product_ids }: { product_ids: number[] }) => {
    const { data } = await api(`/admin/product/deactivate`, {
      method: METHOD.POST,
      data: { product_ids },
    })
    return data
  },

  /**
   * 상품 상태 선택 변경
   */
  postProductStatus: async ({
    product_ids,
    status,
  }: {
    product_ids: number[]
    status: (typeof statusOptionList)[number]["value"]
  }) => {
    const { data } = await api(`/admin/products/status`, {
      method: METHOD.POST,
      data: { product_ids, status },
    })
    return data
  },
}

export default productApi

import React, { useCallback, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Button, Input } from "@runners/ui"
import { useQueryPagination } from "@/_hooks/use-query-pagination"
import { cn } from "@/_styles/cn"
import CommonTable from "./common-table"
import TableDropdown, { TableDropdownItem } from "./table-dropdown"
/**
 * 사용법
 *
 * @returns
 */
export const usePaginationTable = <T,>() => {
  const { setPage, setPageSize, setFilterOption } = useQueryPagination()

  const Table = useCallback(
    <TKey extends string>(props: PaginationTable<T, TKey>) => <PaginationTable {...props} />,
    [],
  )

  const [searchParams] = useSearchParams()

  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1
  const pageSize = searchParams.get("page_size") ? Number(searchParams.get("page_size")) : 10
  const filterOption = searchParams.get("filter_option") || "전체보기"
  return { page, pageSize, setPage, setPageSize, Table, filterOption, setFilterOption }
}

interface PaginationTable<TData, TKey extends string> {
  title: TKey[]
  values: { [key in TKey]: (data: TData) => string | number | JSX.Element }
  data: TData[]
  isLoading: boolean

  className?: string
  widths?: number[]
  href?: (data: TData) => string
  onNext?: (currIdx: number, nextIdx: number) => boolean
  onPrev?: (currIdx: number, nextIdx: number) => boolean

  totalCount?: number

  activeCheck?: boolean
  tableDropdownList?: TableDropdownItem[]
}

/**
 *
 * @param data 테이블에서 보여줄 데이터 리스트입니다
 * @param title 각 열의 제목 입니다
 * @param values 각 열의 값 입니다
 *
 * @param className min-width 등을 지정해서 열이 짜부되지 않게 합니다
 * @param href 행 마다 링크가 필요하다면 적용될 url입니다
 * @param props
 * @returns
 */
const PaginationTable = <TData, TKey extends string>(props: PaginationTable<TData, TKey>) => {
  const {
    // required
    data,
    title,
    values,

    // optional
    href,
    isLoading,

    onNext,
    onPrev,
    widths,
    className,
    activeCheck = false,
    tableDropdownList = [],
    totalCount = null,
  } = props

  const { page, pageSize, setPage, setPageSize } = usePaginationTable()

  const prevPage = () => {
    if (page <= 1) return
    if (onPrev && !onPrev(page, page - 1)) return

    setPage(page - 1)
  }

  const nextPage = () => {
    if (page >= Math.ceil((totalCount || 0) / pageSize)) return
    if (onNext && !onNext(page, page + 1)) return
    setPage(page + 1)
  }

  /**
   * 페이지 이동 인풋
   */
  const [currentPage, setCurrentPage] = useState<string>(`${page}`)
  const handleMovePage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setCurrentPage(value)
  }

  const submitMovePage = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setPage(Number(currentPage))
    }
  }

  /**
   * 셀 체크박스
   */
  const isSelectable = tableDropdownList.length > 0

  const [selectedRows, setSelectedRows] = useState<TData[]>([])

  return (
    <div className={cn("w-full bg-white", className)}>
      {/* page size control */}
      <div className="flex flex-row-reverse px-10 pb-5">
        <div className="flex items-center">
          <span className="text-16 font-medium">페이지 사이즈</span>

          <select
            className="ml-2 border border-gray-300 p-2"
            onChange={e => setPageSize(Number(e.currentTarget.value))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>

      <CommonTable
        data={data}
        widths={widths}
        title={title}
        values={values}
        pageSize={pageSize}
        isLoading={isLoading}
        href={href}
        activeCheck={activeCheck}
        tableDropdownList={tableDropdownList}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <div className="flex items-center justify-center gap-8">
        <Button size="32" color="gray" className="" onClick={prevPage}>
          {"<"}
        </Button>

        <p>
          {page}
          {totalCount != null && totalCount > 0 && (
            <>
              &nbsp;<span className="text-gray-600">{`/ ${Math.ceil(totalCount / pageSize)}`}</span>
            </>
          )}
        </p>

        <Button size="32" color="gray" className="" onClick={nextPage}>
          {">"}
        </Button>

        <div className="">
          <label>페이지 이동 {"->"}</label>
          <Input
            name="page-move-input"
            className="ml-5 w-20 px-1"
            value={currentPage}
            onChange={handleMovePage}
            onKeyDown={submitMovePage}
          />
        </div>
      </div>

      {isSelectable && (
        <div className="">
          <TableDropdown
            items={tableDropdownList}
            selectedRows={selectedRows as TData & { id: number }[]}
            clear={() => setSelectedRows([])}
          />
        </div>
      )}
    </div>
  )
}

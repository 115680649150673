import { DjangoListResponse, DjangoResponse, DjangoSearchOrderingParams } from "@runners/interface"
import api, { METHOD } from "."
import {
  AdminProductGroupDto,
  AdminProductGroupListDto,
  PatchAdminProductGroupPayload,
  PostAdminProductGroupPayload,
} from "./product-group.type"

/** ------------------------------------------------------------------------------
 * 
 * 상품 그룹 - 어드민
 * 
 ------------------------------------------------------------------------------ */
const productGroupApi = {
  /**
   * 상품 그룹 객체 조회
   */
  getProductGroup: async ({ productGroupId }: { productGroupId: number }) => {
    const { data } = await api<DjangoResponse<AdminProductGroupDto>>(`/admin/product-group/${productGroupId}`)
    return data
  },

  /**
   * 상품 그룹 리스트 조회
   */
  getProductGroupList: async (params: DjangoSearchOrderingParams) => {
    const { data } = await api<DjangoListResponse<AdminProductGroupListDto>>("/admin/product-groups", { params })
    return data
  },

  /**
   * 상품 그룹 수정
   */
  patchProductGroup: async ({
    productGroupId,
    payload,
  }: {
    productGroupId: number
    payload: PatchAdminProductGroupPayload
  }) => {
    const { data } = await api<DjangoResponse<any>>(`/admin/product-group/${productGroupId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },

  /**
   * 상품 그룹 생성
   */
  postProductGroup: async (payload: PostAdminProductGroupPayload) => {
    const { data } = await api<DjangoResponse<any>>("/admin/product-group", {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },
}

export default productGroupApi

import { cn } from "@runners/lib"
import { Badge } from "@runners/ui"

interface AdvertisementMarkProps {
  className?: string
}

/**
 * className은 위치 조정으로만 사용해주세요.
 */
const AdvertisementMark = (props: AdvertisementMarkProps) => {
  const { className } = props
  return <Badge className={cn("absolute right-0 z-10 rounded-1 bg-gray-700 font-bold text-white", className)}>AD</Badge>
}

export default AdvertisementMark

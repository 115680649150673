import { Button, Image } from "@runners/ui"
import AdvertisementMark from "../../components/advertisement-mark"

interface StoryDetailCardLongTypeBannerProps {
  title: string
  description: string
  button_title?: string
  button_title_color: string
  button_background_color: string

  link: string

  thumbnail_image_url: string

  is_advertisement?: boolean
}

const StoryDetailCardLongTypeBanner = (props: StoryDetailCardLongTypeBannerProps) => {
  const {
    link,
    thumbnail_image_url,
    title,
    button_background_color,
    button_title_color,
    description,
    button_title,
    is_advertisement,
  } = props

  return (
    <a href={link}>
      <div className="relative h-[480px] w-[325px]">
        <div className="px-7 py-[140px]">
          <p className="text-14 text-gray-700">{title}</p>
          <div className="mt-2.5" />
          <p className="whitespace-pre-wrap text-20 font-bold text-gray-900">{description}</p>
          <div className="mt-[30px]" />
          {button_title && (
            <a href={link}>
              <Button
                size="40"
                style={{ backgroundColor: button_background_color, color: button_title_color }}
                className="rounded-full"
              >
                {button_title}
              </Button>
            </a>
          )}
        </div>
        <div className="absolute top-0 -z-10 size-full">
          {is_advertisement && <AdvertisementMark className="m-5" />}
          <Image size="2560" className="size-full object-cover" src={thumbnail_image_url} />
        </div>
      </div>
    </a>
  )
}

export default StoryDetailCardLongTypeBanner

import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import AdminRankingPostPage from "@/_pages/service/ranking-post/admin-ranking-post-page"
import AdminRankingPostCreatePage from "@/_pages/service/ranking-post/create/admin-ranking-post-create-page"
import AdminRankingPostDetailPage from "@/_pages/service/ranking-post/detail/admin-ranking-post-detail-page"
import GlobalLayout from "./_components/layout/global-layout"
import Dashboard from "./_pages/dashboard"
import AdminAddressBookPage from "./_pages/email/address-book/admin-address-book-page"
import AdminAddressBookCreatePage from "./_pages/email/address-book/create/admin-address-book-create-page"
import AdminAddressBookDetailPage from "./_pages/email/address-book/detail/admin-address-book-detail-page"
import AdminEmailSendPage from "./_pages/email/send/admin-email-send-page"
import AdminEmailSendCreatePage from "./_pages/email/send/create/admin-email-send-create-page"
import AdminEmailTemplatePage from "./_pages/email/template/admin-email-template-page"
import AdminEmailTemplateCreatePage from "./_pages/email/template/create/admin-email-template-create-page"
import AdminEmailTemplateDetailPage from "./_pages/email/template/detail/admin-email-template-detail-page"
import BannerPage from "./_pages/service/banner/admin-banner-page"
import AdminBannerCreatePage from "./_pages/service/banner/create/admin-banner-create-page"
import AdminBannerDetailPage from "./_pages/service/banner/detail/admin-banner-detail-page"
import EventPage from "./_pages/service/event/admin-event-page"
import LabelPage from "./_pages/service/label/admin-label-page"
import AdminLabelCreatePage from "./_pages/service/label/create/admin-label-create-page"
import AdminLabelDetailPage from "./_pages/service/label/detail/admin-label-detail-page"
import PostPage from "./_pages/service/post/admin-post-page"
import AdminPostCreatePage from "./_pages/service/post/create/admin-post-create-page"
import AdminPostDetailPage from "./_pages/service/post/detail/admin-post-detail-page"
import UserPage from "./_pages/service/user/admin-user-page"
import AdminCampaignPage from "./_pages/store/campaign/admin-campaign-page"
import AdminCampaignCreatePage from "./_pages/store/campaign/create/admin-campaign-create-page"
import AdminCampaignDetailPage from "./_pages/store/campaign/detail/admin-campaign-detail-page"
import ProductPage from "./_pages/store/product/admin-product-page"
import AdminProductGroupPage from "./_pages/store/product-group/admin-product-group-page"
import AdminProductGroupCreatePage from "./_pages/store/product-group/create/admin-product-group-create-page"
import AdminProductGroupDetailPage from "./_pages/store/product-group/detail/admin-product-group-detail-page"
import AdminRecommendProductPage from "./_pages/store/recommend-product/admin-recommend-product-page"
import AdminCreateRecommendProductPage from "./_pages/store/recommend-product/create/admin-create-recommend-product-page"
import AdminRecommendProductDetailPage from "./_pages/store/recommend-product/detail/admin-recommend-product-detail-page"
import SellerPage from "./_pages/store/seller/admin-seller-page"
import AdminSellerDetailPage from "./_pages/store/seller/detail/admin-seller-detail-page"
import AdminSettlementPage from "./_pages/store/settlement/admin-settlement-page"

const AdminRouter = (
  <Route element={<GlobalLayout />}>
    <Route path="/" element={<Dashboard />} />

    <Route path="service">
      <Route path="user" element={<UserPage />} />
      <Route path="event" element={<EventPage />} />
      <Route path="label" element={<LabelPage />} />
      <Route path="label/create" element={<AdminLabelCreatePage />} />
      <Route path="label/:labelId" element={<AdminLabelDetailPage />} />
      <Route path="post" element={<PostPage />} />
      <Route path="post/create" element={<AdminPostCreatePage />} />
      <Route path="post/:postId" element={<AdminPostDetailPage />} />
      <Route path="ranking-post" element={<AdminRankingPostPage />} />
      <Route path="ranking-post/create" element={<AdminRankingPostCreatePage />} />
      <Route path="ranking-post/:postId" element={<AdminRankingPostDetailPage />} />
      <Route path="banner" element={<BannerPage />} />
      <Route path="banner/create" element={<AdminBannerCreatePage />} />
      <Route path="banner/:bannerId" element={<AdminBannerDetailPage />} />
    </Route>

    <Route path="store">
      <Route path="campaign" element={<AdminCampaignPage />} />
      <Route path="campaign/create" element={<AdminCampaignCreatePage />} />
      <Route path="campaign/:campaignId" element={<AdminCampaignDetailPage />} />
      <Route path="seller" element={<SellerPage />} />
      <Route path="seller/:storeId" element={<AdminSellerDetailPage />} />
      <Route path="product" element={<ProductPage />} />

      <Route path="p-group" element={<AdminProductGroupPage />} />
      <Route path="p-group/create" element={<AdminProductGroupCreatePage />} />
      <Route path="p-group/:productGroupId" element={<AdminProductGroupDetailPage />} />

      <Route path="recommend-p" element={<AdminRecommendProductPage />} />
      <Route path="recommend-p/create" element={<AdminCreateRecommendProductPage />} />
      <Route path="recommend-p/:recommendProductId" element={<AdminRecommendProductDetailPage />} />

      <Route path="settlement" element={<AdminSettlementPage />} />
    </Route>

    <Route path="email">
      <Route path="template" element={<AdminEmailTemplatePage />} />
      <Route path="template/create" element={<AdminEmailTemplateCreatePage />} />
      <Route path="template/:templateId" element={<AdminEmailTemplateDetailPage />} />

      <Route path="send" element={<AdminEmailSendPage />} />
      <Route path="send/create" element={<AdminEmailSendCreatePage />} />

      <Route path="address-book" element={<AdminAddressBookPage />} />
      <Route path="address-book/create" element={<AdminAddressBookCreatePage />} />
      <Route path="address-book/:addressBookId" element={<AdminAddressBookDetailPage />} />
    </Route>
  </Route>
)

export default createBrowserRouter(createRoutesFromElements([AdminRouter]))

import { useFormContext } from "react-hook-form"
import useIsMobile from "@/_hooks/use-is-mobile"
import { useModalStack } from "@/_zustand/admin-modal-stack"

interface usePreviewModalProps {
  Component: (props: any) => JSX.Element
  key: string
}

const usePreviewModal = (props: usePreviewModalProps) => {
  const { Component, key } = props

  const modalStack = useModalStack()

  const { getValues } = useFormContext()

  const { isMobile } = useIsMobile()

  const openModal = () => {
    const values = getValues()

    modalStack.open({
      key,
      Component,
      componentProps: {
        ...values,
        position: values?.position?.value,
        isMobile,
      },
    })
  }
  return {
    openModal,
  }
}

export default usePreviewModal

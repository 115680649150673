import { Image } from "@runners/ui"

interface ProductDetailBandTypeBannerProps {
  thumbnail_image_url: string
  description: string
}

const ProductDetailBandTypeBanner = (props: ProductDetailBandTypeBannerProps) => {
  const { description, thumbnail_image_url } = props
  return (
    <div className="flex h-9 w-[311px] flex-row items-center rounded-1 bg-primary-100 px-3 py-1 md:h-[50px] md:w-[464px] md:px-4 md:py-2">
      <div className="flex flex-row items-center">
        <Image className="size-7 md:size-9" size="2560" src={thumbnail_image_url} />
        <div dangerouslySetInnerHTML={{ __html: description }} className="ml-1 text-14 font-bold md:text-16" />
      </div>
    </div>
  )
}

export default ProductDetailBandTypeBanner

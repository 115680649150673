import { useFormContext } from "react-hook-form"
import { Button, Input } from "@runners/ui"
import AdminFormField from "@/_components/admin-form-field"
import usePreviewModal from "@/_pages/service/banner/_hooks/use-preview-modal"
import ColorPickerLabelButton from "../../../color-picker-label-button"
import StoryCardTopBanner from "../../../preview/story/story-card-top-banner"
import ShouldUnregisterThumbnailInput from "../../../should-unregister-thumbnail-input"
import AdminHomeDefaultFields from "../../home/admin-home-default-fields"

const AdminStoryDetailCardShortFields = () => {
  const { register } = useFormContext()
  const { openModal } = usePreviewModal({
    Component: StoryCardTopBanner,
    key: "admin-story-detail-card-short-preview",
  })
  return (
    <>
      <AdminHomeDefaultFields />
      <AdminFormField label="버튼 텍스트">
        <Input {...register("button_title")} className="min-w-[400px]" />
      </AdminFormField>
      <div className="flex flex-row items-start justify-start gap-4">
        <ColorPickerLabelButton label="버튼 텍스트 색상 선택" name="button_title_color" />
        <ColorPickerLabelButton label="버튼 배경 색상 선택" name="button_background_color" />
      </div>
      <ShouldUnregisterThumbnailInput name="thumbnail_image_url" label="웹 이미지" className="h-[230px] w-[325px]" />
      <div>
        <Button size="48" onClick={openModal}>
          미리보기
        </Button>
      </div>
    </>
  )
}

export default AdminStoryDetailCardShortFields

import { DjangoListResponse, DjangoResponse } from "@runners/interface"
import api, { METHOD } from "."
import { AdminBannerListDto, AdminBannerListParams, PatchBannerPayload, PostBannerPayload } from "./banner.type"

/** ------------------------------------------------------------------------------
 * 
 * 10. 배너 - 어드민
 * 
 ------------------------------------------------------------------------------ */
const bannerApi = {
  /**
   * 배너 선택 삭제
   */
  deleteBanners: async (params: { banner_ids: number[] }) => {
    const response = await api(`/admin/banners`, {
      method: METHOD.DELETE,
      params,
    })
    return response.data
  },

  /**
   * 배너 조회
   */
  getBanner: async ({ bannerId }: { bannerId: number }) => {
    const response = await api<DjangoResponse<AdminBannerListDto>>(`/admin/banner/${bannerId}`)
    return response.data
  },

  /**
   * 배너 리스트 조회
   */
  getBannerList: async (params: AdminBannerListParams) => {
    const response = await api<DjangoListResponse<AdminBannerListDto>>(`/admin/banners`, { params })
    return response.data
  },

  /**
   * 배너 수정
   */
  patchBanner: async ({ bannerId, payload }: { bannerId: number; payload: PatchBannerPayload }) => {
    const response = await api(`/admin/banner/${bannerId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return response.data
  },

  /**
   * 배너 생성
   */
  postBanner: async (payload: PostBannerPayload) => {
    const response = await api(`/admin/banner`, {
      method: METHOD.POST,
      data: payload,
    })
    return response.data
  },

  /**
   * 배너 선택 활성화
   */
  postBannerActivate: async (payload: { banner_ids: number[] }) => {
    const response = await api(`/admin/banner/activate`, {
      method: METHOD.POST,
      data: payload,
    })
    return response.data
  },

  /**
   * 배너 선택 비활성화
   */
  postBannerDeactivate: async (payload: { banner_ids: number[] }) => {
    const response = await api(`/admin/banner/deactivate`, {
      method: METHOD.POST,
      data: payload,
    })
    return response.data
  },
}

export default bannerApi

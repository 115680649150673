import { ChromePicker, ColorResult } from "react-color"
import { Button } from "@runners/ui"

interface ColorPickerProps {
  onChange: (color: ColorResult) => void
  color: string
  close: () => void
}

const ColorPicker = (props: ColorPickerProps) => {
  const { color, onChange, close } = props
  return (
    <div className="absolute z-10 w-[229px]">
      <ChromePicker color={color} className="rounded-5" onChange={onChange} disableAlpha={true} />
      <Button size="48" className="w-full font-bold" onClick={close}>
        닫기
      </Button>
    </div>
  )
}

export default ColorPicker

import { useEffect, useState } from "react"

// 767px
const DEVICE_BOUNDARY = 767

/** ------------------------------------------------------------------------------
 * 
 * 미디어쿼리로 해결할수없는 컴포넌트 분리 시 사용되는 훅
 * 
 * 
 ------------------------------------------------------------------------------ */
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const handler = () => {
    if (window.innerWidth < DEVICE_BOUNDARY) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    handler()
    window.addEventListener("resize", handler)
    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [])

  return { isMobile }
}

export default useIsMobile

import { Controller, useFormContext } from "react-hook-form"
import ReactSelect from "react-select"
import { Button, Input } from "@runners/ui"
import AdminFormField from "@/_components/admin-form-field"
import AdminHomeDefaultFields from "./admin-home-default-fields"
import usePreviewModal from "../../../_hooks/use-preview-modal"
import ColorPickerLabelButton from "../../color-picker-label-button"
import HomeObjectTypeBanner from "../../preview/home/home-object-type-banner"
import ShouldUnregisterThumbnailInput from "../../should-unregister-thumbnail-input"

const ADMIN_HOME_OBJECT_POSITIONS = [
  { label: "좌측", value: "left" },
  { label: "우측", value: "right" },
  { label: "중앙", value: "center" },
]

const AdminHomeObject = () => {
  const { register } = useFormContext()

  const { openModal } = usePreviewModal({
    Component: HomeObjectTypeBanner,
    key: "admin-home-object-preview",
  })

  return (
    <>
      <AdminFormField label="라벨" required>
        <Input className="min-w-[400px]" {...register("label_title")} />
      </AdminFormField>
      <ColorPickerLabelButton name="label_color" label="라벨 텍스트 색상 선택" />
      <AdminHomeDefaultFields />
      <AdminFormField label="위치" required>
        <Controller
          name="position"
          render={({ field }) => (
            <ReactSelect {...field} className="min-w-[400px]" options={ADMIN_HOME_OBJECT_POSITIONS} />
          )}
        />
      </AdminFormField>

      {/* NOTE: 베너 component만들 때 유의점 이미지크기를 그대로 유지해야하며 position에 따라 위치 조정 필요 */}
      <ShouldUnregisterThumbnailInput
        name="thumbnail_image_url"
        label="웹 이미지"
        key="web-image"
        className="size-[370px]"
      />
      <ShouldUnregisterThumbnailInput
        name="mobile_thumbnail_image_url"
        label="모바일 이미지"
        key="mobile-image"
        className="h-[223px] w-[335px]"
      />

      {/* Preview */}
      <div>
        <Button size="48" onClick={openModal}>
          미리보기
        </Button>
      </div>
    </>
  )
}

export default AdminHomeObject

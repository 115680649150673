import { DjangoListResponse, DjangoResponse } from "@runners/interface"
import api, { METHOD } from "."
import { PatchStorePayload, StoreDto, StoreListDto, StoreListParams, StoreSellerDto } from "./store.type"

const storeApi = {
  /**
   * 스토어 삭제
   */
  deleteStore: async ({ storeId }: { storeId: number }) => {
    const { data } = await api(`/admin/store/${storeId}`, {
      method: METHOD.DELETE,
    })
    return data
  },

  /**
   * 스토어 선택 삭제
   */
  deleteStores: async ({ store_ids }: { store_ids: number[] }) => {
    const { data } = await api(`/admin/stores`, {
      method: METHOD.DELETE,
      params: { store_ids },
    })
    return data
  },

  /**
   * 스토어 객체 조회
   */
  getStore: async ({ storeId }: { storeId: number }) => {
    const { data } = await api<DjangoResponse<StoreDto>>(`/admin/store/${storeId}`)
    return data
  },

  /**
   * 어드민 스토어 관리자 리스트 조회
   *
   * @comment 현재 사용 안함
   */
  getStoreSellerList: async ({ storeId, params }: { storeId: number; params: unknown }) => {
    const { data } = await api.get<DjangoListResponse<StoreSellerDto>>(`/admin/store/${storeId}/sellers`, { params })
    return data
  },

  /**
   * 스토어 리스트 조회
   */
  getStoreList: async (params: StoreListParams) => {
    const { data } = await api<DjangoListResponse<StoreListDto>>(`/admin/stores`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 스토어 수정
   */
  patchStore: async ({ storeId, payload }: { storeId: number; payload: PatchStorePayload }) => {
    const { data } = await api(`/admin/store/${storeId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },

  /**
   * 스토어 선택 활성화
   */
  postStoreActivate: async ({ store_ids }: { store_ids: number[] }) => {
    const { data } = await api(`/admin/store/activate`, {
      method: METHOD.POST,
      data: { store_ids },
    })
    return data
  },

  /**
   * 스토어 선택 비활성화
   */
  postStoreDeactivate: async ({ store_ids }: { store_ids: number[] }) => {
    const { data } = await api(`/admin/store/deactivate`, {
      method: METHOD.POST,
      data: { store_ids },
    })
    return data
  },
}

export default storeApi

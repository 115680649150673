import { useRef } from "react"
import { AdminComment } from "@runners/interface"
import { Button, Input, Textarea } from "@runners/ui"
import withModalStackProps from "@/_hoc/with-modal-stack-props"

interface AddCommentModalProps {
  onSubmit: (data: AdminComment) => void
}

const AddCommentModal = withModalStackProps<AddCommentModalProps>(props => {
  const { close, onSubmit } = props

  const titleRef = useRef<HTMLInputElement>(null)
  const commentRef = useRef<HTMLTextAreaElement>(null)

  const handleSubmit = () => {
    const data = {
      title: titleRef.current?.value || "",
      comment: commentRef.current?.value || "",
    }

    onSubmit(data)
    close()
  }

  return (
    <div className="flex w-96 flex-col gap-4 bg-white p-4">
      <div className="text-18 font-bold">코멘트 추가</div>

      <div>
        <div>제목</div>
        <Input ref={titleRef} defaultValue="추천하는 이유" className="w-full" />
      </div>
      <div>
        <div>내용</div>
        <Textarea ref={commentRef} className="h-40 w-full" />
      </div>

      <div className="flex justify-end gap-2">
        <Button type="button" onClick={close} color="gray" size="48" className="w-24">
          취소
        </Button>
        <Button type="button" onClick={handleSubmit} size="48" className="w-24">
          적용
        </Button>
      </div>
    </div>
  )
})

export default AddCommentModal

import { DjangoSearchOrderingParams } from "@runners/interface"
import { useQuery } from "@tanstack/react-query"
import productGroupQueryKey from "@/_api/_query-key/product-group"
import productGroupApi from "@/_api/product-group"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetProductGroupListQuery = (params: DjangoSearchOrderingParams) => {
  return useInfiniteData({
    queryKey: productGroupQueryKey.productGroupList(params),
    queryFn: () => productGroupApi.getProductGroupList(params),
  })
}

export const useGetProductGroupQuery = ({ productGroupId }: { productGroupId: number }) => {
  return useQuery({
    queryKey: productGroupQueryKey.detail({ productGroupId }),
    queryFn: () => productGroupApi.getProductGroup({ productGroupId }),
    select: response => response.data,
  })
}

import { DjangoListResponse, DjangoResponse } from "@runners/interface"
import api, { METHOD } from "."
import { AdminUserListDto, AdminUserListParams, PostUserLoginDto, UserMeDto } from "./user.type"

/** ------------------------------------------------------------------------------
 * 
 * user 관련은 센스 API 사용
 * 
 ------------------------------------------------------------------------------ */
const userApi = {
  getUserMe: async () => {
    const { data } = await api<DjangoResponse<UserMeDto>>(`/user/me`, {
      method: METHOD.GET,
    })
    return data
  },

  postUserLogin: async (payload: { email: string; password: string }) => {
    const { data } = await api<DjangoResponse<PostUserLoginDto>>(`/user/login`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /** ------------------------------------------------------------------------------
   * 
   * 여기부터 어드민
   * 
   ------------------------------------------------------------------------------ */
  getUserList: async (params: AdminUserListParams) => {
    const { data } = await api<DjangoListResponse<AdminUserListDto>>(`/admin/users`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 어드민에서 스토어로 로그인
   */
  patchAdminUserMe: async ({ store }: { store: number }) => {
    const { data } = await api(`/admin/user/me`, {
      method: METHOD.PATCH,
      data: { store },
    })
    return data
  },
}

export default userApi

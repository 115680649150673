import { useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import ColorPicker from "./color-picker"

interface ColorPickerLabelButtonProps {
  name: string
  label: string
}

const ColorPickerLabelButton = (props: ColorPickerLabelButtonProps) => {
  const { label, name } = props
  const [isOpenColorPicker, setIsOpenColorPicker] = useState(false)
  const { register, setValue, control } = useFormContext()

  const textColor = useWatch({
    name,
    control,
  })

  return (
    <div>
      <button
        type="button"
        className="rounded-4 border-2 bg-primary-100 px-4 py-2"
        onClick={() => setIsOpenColorPicker(true)}
      >
        {label}
      </button>
      {isOpenColorPicker && (
        <ColorPicker
          color={textColor ?? "#000000"}
          onChange={color => setValue(name, color.hex)}
          close={() => setIsOpenColorPicker(false)}
        />
      )}
      <input type="hidden" {...register(name)} />
    </div>
  )
}

export default ColorPickerLabelButton

import { DjangoResponse, LinkPreviewDto } from "@runners/interface"
import api, { METHOD } from "."

const utilsApi = {
  getLinkPreview: async (url: string) => {
    const { data } = await api<DjangoResponse<LinkPreviewDto>>(`/utils/link-preview`, {
      method: METHOD.GET,
      params: { url },
    })
    return data
  },
}

export default utilsApi

import { DjangoSearchOrderingParams } from "@runners/interface"

const recommendProductQueryKey = {
  recommendProduct: () => ["recommend-product"],
  adminRecommendProduct: () => ["admin", "recommend-product"],

  recommendProductList: (params: DjangoSearchOrderingParams) => [
    ...recommendProductQueryKey.adminRecommendProduct(),
    "list",
    params,
  ],
  detail: ({ recommendProductId }: { recommendProductId: number }) => [
    ...recommendProductQueryKey.adminRecommendProduct(),
    "detail",
    recommendProductId,
  ],
}

export default recommendProductQueryKey

import { Image } from "@runners/ui"
import AdvertisementMark from "../components/advertisement-mark"

interface HomeThumbnailTypeBannerProps {
  label: string
  label_color: string

  title: string
  description: string

  thumbnail_image_url: string
  mobile_thumbnail_image_url: string

  is_advertisement: string

  link: string

  isMobile?: boolean
}

const HomeThumbnailTypeBanner = (props: HomeThumbnailTypeBannerProps) => {
  const {
    description,
    is_advertisement,
    label,
    label_color,
    link,
    thumbnail_image_url,
    title,
    mobile_thumbnail_image_url,
    isMobile,
  } = props

  if (isMobile) {
    return (
      <a href={link}>
        <div className="relative h-[223px] w-[335px] overflow-hidden">
          <div className="flex size-full flex-col justify-end px-7 pb-6">
            <p className="whitespace-pre-wrap text-20 font-bold leading-[26px]">{title}</p>
            <p className="text-14 font-medium text-gray-600">{description}</p>
          </div>

          <div className="absolute top-0 -z-10 flex size-full">
            {is_advertisement && <AdvertisementMark className="m-5" />}
            <Image size="2560" className="object-fill" src={mobile_thumbnail_image_url} />
          </div>
        </div>
      </a>
    )
  }

  return (
    <a href={link}>
      <div className="h-[416px] w-[1920px] overflow-hidden bg-white px-[200px]">
        <div className="grid h-full grid-cols-[3fr_2fr]">
          {/* 택스트 영역 */}
          <div className="h-full w-[481px]">
            <p style={{ color: label_color }} className="font-bold text-primary md:text-24">
              {label}
            </p>
            <div className="mt-5" />
            <p className="whitespace-pre-wrap text-[48px] font-bold">{title}</p>
            <div className="mt-[30px]" />
            <p className="text-[18px] font-medium leading-[28px] text-gray-600">{description}</p>
          </div>
          {/* 이미지 영역 */}
          <div className="relative flex h-full w-[624px]">
            {is_advertisement && <AdvertisementMark className="m-5" />}
            <div className="relative size-full">
              <Image size="2560" className="size-full rounded-[30px] object-fill" src={thumbnail_image_url} />
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default HomeThumbnailTypeBanner

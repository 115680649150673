import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import AdminCreateLayout from "@/_components/layout/admin-create-layout"
import useToast from "@/_hooks/use-toast"
import BannerForm, { AdminBannerFormType } from "../_components/banner-form"
import { BannerDisplayType, BannerType } from "../_constants"
import { usePostBannerMutation } from "../_hooks/use-mutation"

const AdminBannerCreatePage = () => {
  const methods = useForm<AdminBannerFormType>({
    shouldUnregister: true,
  })

  const toast = useToast()
  const { mutate: createMutate } = usePostBannerMutation({
    onSuccess: () => {
      toast.success("배너 생성 완료")
      history.back()
    },
  })

  const onSubmit: SubmitHandler<AdminBannerFormType> = data => {
    createMutate({
      ...data,
      type: data.type.value as BannerType,
      display_type: data.display_type.value as BannerDisplayType,
      order: Number(data.order),
    })
  }

  return (
    <FormProvider {...methods}>
      <AdminCreateLayout onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex items-center justify-end"></div>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <BannerForm />
        </form>
      </AdminCreateLayout>
    </FormProvider>
  )
}

export default AdminBannerCreatePage

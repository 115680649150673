import { Button } from "@runners/ui"
import BannerEditor from "@/_components/editorjs/banner-editor"
import ThumbnailInput from "@/_components/react-hook-form/thumbnail-input"
import usePreviewModal from "@/_pages/service/banner/_hooks/use-preview-modal"
import ProductDetailBandTypeBanner from "../../../preview/product/detail/product-detail-band-type-banner"

const AdminProductDetailBandFields = () => {
  const { openModal } = usePreviewModal({
    Component: ProductDetailBandTypeBanner,
    key: "admin-product-detail-band-preview",
  })
  return (
    <>
      <ThumbnailInput name="thumbnail_image_url" label="웹 이미지" key="web-image" className="size-9" />
      <BannerEditor />
      <div>
        <Button size="40" onClick={openModal}>
          미리보기
        </Button>
      </div>
    </>
  )
}

export default AdminProductDetailBandFields

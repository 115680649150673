import { DjangoListResponse, DjangoResponse } from "@runners/interface"
import api, { METHOD } from "."
import {
  AdminLabelDto,
  AdminLabelListDto,
  AdminLabelListParams,
  PatchAdminLabelPayload,
  PostAdminLabelPayload,
} from "./label.type"

const labelApi = {
  /**
   * 선택 라벨 삭제
   */
  deleteLabelList: async ({ label_ids }: { label_ids: number[] }) => {
    const { data } = await api(`/admin/labels`, {
      method: METHOD.DELETE,
      params: { label_ids },
    })
    return data
  },
  /**
   * 라벨 객체 조회
   */
  getLabel: async ({ labelId }: { labelId: number }) => {
    const { data } = await api.get<DjangoResponse<AdminLabelDto>>(`/admin/label/${labelId}`)
    return data
  },

  /**
   * 라벨 리스트 조회
   */
  getLabelList: async (params: AdminLabelListParams) => {
    const { data } = await api<DjangoListResponse<AdminLabelListDto>>(`/admin/labels`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 라벨 수정
   */
  patchLabel: async ({ labelId, payload }: { labelId: number; payload: PatchAdminLabelPayload }) => {
    const { data } = await api(`/admin/label/${labelId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },

  /**
   * 라벨 생성
   */
  postLabel: async ({ payload }: { payload: PostAdminLabelPayload }) => {
    const { data } = await api(`/admin/label`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 라벨 선택 활성화
   */
  postLabelActivate: async ({ label_ids }: { label_ids: number[] }) => {
    const { data } = await api(`/admin/label/activate`, {
      method: METHOD.POST,
      data: { label_ids },
    })
    return data
  },

  /**
   * 라벨 선택 비활성화
   */
  postLabelDeactivate: async ({ label_ids }: { label_ids: number[] }) => {
    const { data } = await api(`/admin/label/deactivate`, {
      method: METHOD.POST,
      data: { label_ids },
    })
    return data
  },
}

export default labelApi

import { useFormContext } from "react-hook-form"
import ThumbnailInput from "@/_components/react-hook-form/thumbnail-input"

interface ShouldUnregisterThumbnailInputProps {
  name: string
  label?: string
  className?: string
}

/**
 * NOTE: react hook form의 shouldUnregister를 이용하기 위하여 dummy input을 항상 랜더링 시키도록 만든 컴포넌트 입니다.
 */
const ShouldUnregisterThumbnailInput = (props: ShouldUnregisterThumbnailInputProps) => {
  const { register } = useFormContext()

  return (
    <>
      <ThumbnailInput {...props} />
      <input type="hidden" {...register(props.name)} />
    </>
  )
}

export default ShouldUnregisterThumbnailInput

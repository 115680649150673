import React, { useEffect } from "react"
import dayjs from "dayjs"
import { AdminEventListDto, AdminEventListParams } from "@/_api/event.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import FilterDropdownTable from "@/_components/table/filter-dropdown-table"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { useTableSearch } from "@/_hooks/use-table-search"
import { useGetEventList } from "./hooks/use-get-query"

interface EventPageProps {}

const EventPage = (props: EventPageProps) => {
  const {} = props

  // TODO: any
  const { Table, page, pageSize, setPage, filterOption, setFilterOption } = usePaginationTable<AdminEventListDto>()
  const { SearchInput, search } = useTableSearch()

  const params: AdminEventListParams = {
    page,
    page_size: pageSize,
    search,
    is_staff_user: filterOption === "스태프만 보기" ? true : filterOption === "유저만 보기" ? false : undefined,
  }

  const { data: eventList, totalCount, isLoading } = useGetEventList(params)

  useEffect(() => {
    setPage(1)
  }, [filterOption])

  return (
    <div className="">
      <AdminPageLayout totalCount={totalCount}>
        <div className="max-w-[calc(100vw-264px)] overflow-x-auto">
          <div className="flex w-full flex-row justify-between gap-12">
            <SearchInput
              className="w-[500px]"
              label="검색 필터"
              description="가능한 필드: 유형, 종류, 작성자, 이메일, 이벤트 명, 유저생년월일"
              placeholder="검색어 입력"
            />
            <FilterDropdownTable filterOption={filterOption} setFilterOption={setFilterOption} />
          </div>
          <p className="text-14">
            현재 검색어: <span className="font-bold">{search}</span>
          </p>
          <Table
            totalCount={totalCount}
            isLoading={isLoading}
            data={eventList}
            className="min-w-[1600px]"
            title={["ID", "유형", "종류", "작성자", "이벤트명", "멤버", "날짜", "지역", "생성일"]}
            widths={[2, 3, 3, 5, 5, 5, 4, 4, 3]}
            values={{
              ID: value => value?.id,
              유형: value => (
                <div className="flex items-center justify-center">
                  <div
                    style={{
                      background: value?.event_category?.background_color,
                      color: value?.event_category?.text_color,
                    }}
                    className="w-fit items-center px-2"
                  >
                    {value?.event_category?.title}
                  </div>
                </div>
              ),
              종류: value => {
                return value.recommend_categories?.map((v: any) => v.title)?.join(", ")
              },
              작성자: value => (
                <div>
                  <p>{value?.user?.username}</p>
                  <p className="text-12 text-gray-600">{value?.user?.email}</p>
                </div>
              ),
              이벤트명: value => {
                return value?.title
              },
              멤버: value => {
                return value?.members?.length || 0
              },
              날짜: value => {
                return dayjs(value?.date).format("YY.MM.DD")
              },
              지역: value => {
                return value?.place?.address
              },
              생성일: value => {
                return dayjs(value?.created).format("YY.MM.DD")
              },
            }}
          />
        </div>
        <div className="h-10" />
      </AdminPageLayout>
    </div>
  )
}

export default EventPage

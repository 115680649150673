import { Button } from "@runners/ui"
import BannerEditor from "@/_components/editorjs/banner-editor"
import usePreviewModal from "@/_pages/service/banner/_hooks/use-preview-modal"
import StoryDetailMobileBandTypeBanner from "../../../../preview/story/detail/mobile/story-detail-mobile-band-type-banner"
import ShouldUnregisterThumbnailInput from "../../../../should-unregister-thumbnail-input"

const AdminStoryDetailMobileBandFileds = () => {
  const { openModal } = usePreviewModal({
    Component: StoryDetailMobileBandTypeBanner,
    key: "admin-story-detail-mobile-band-preview",
  })

  return (
    <>
      <BannerEditor />
      <ShouldUnregisterThumbnailInput name="thumbnail_image_url" label="모바일 이미지" className="h-[84px] w-[335px]" />
      <div>
        <Button size="48" onClick={openModal}>
          미리보기
        </Button>
      </div>
    </>
  )
}

export default AdminStoryDetailMobileBandFileds

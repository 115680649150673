/**
 * 배너의 타입은 백엔드기준 7종류가 있습니다. ->
 * 배너 시스템이 개선되며 프론트에서 임의로 추가하여 작업하는 형식으로 변경
 */

export enum BannerType {
  NOTICE = "NOTICE",
  EVENT = "EVENT",
  POST = "POST",
  STORY = "STORY",
  PRODUCT = "PRODUCT",
  ADVERTISEMENT = "ADVERTISEMENT",
  ETC = "ETC",
}

/**
 * 배너의 디스플레이 타입은 백엔드 기준 3가지가 있습니다. ->
 * 배너 시스템이 개선되며 프론트에서 임의로 추가하여 작업하는 형식으로 변경
 */
export enum BannerDisplayType {
  POST = "POST",
  SHOPPING = "SHOPPING",
  STORE = "STORE",
  HOME_OBJECT = "HOME_OBJECT",
  HOME_THUMBNAIL = "HOME_THUMBNAIL",
  HOME_BUTTON = "HOME_BUTTON",
  SHOPPING_IMAGE = "SHOPPING_IMAGE",
  PRODUCT_DETAIL_CARD = "PRODUCT_DETAIL_CARD",
  PRODUCT_DETAIL_BAND = "PRODUCT_DETAIL_BAND",
  STORY_CARD_TOP = "STORY_CARD_TOP",
  STORY_CARD_BOTTOM = "STORY_CARD_BOTTOM",
  STORY_DETAIL_CARD = "STORY_DETAIL_CARD",
  STORY_DETAIL_CARD_SHORT = "STORY_DETAIL_CARD_SHORT",
  STORY_DETAIL_CARD_LONG = "STORY_DETAIL_CARD_LONG",
  STORY_DETAIL_MOBILE_BAND = "STORY_DETAIL_MOBILE_BAND",
  CONTENT_DETAIL_BAND = "CONTENT_DETAIL_BAND",
}

/**
 * 백엔드 배너 타입에 따른 label 이 추가된 객체배열
 */
export const BANNER_TYPE = [
  { label: "광고", value: BannerType.ADVERTISEMENT },
  { label: "게시글", value: BannerType.POST },
  { label: "이벤트", value: BannerType.EVENT },
  { label: "공지", value: BannerType.NOTICE },
  { label: "상품", value: BannerType.PRODUCT },
  { label: "스토리", value: BannerType.STORY },
  { label: "기타", value: BannerType.ETC },
]

/**
 * 백엔드 디스플레이 타입에 따른 label 추가된 객체배열
 */
export const BANNER_DISPLAY_TYPE = [
  { label: "게시글", value: BannerDisplayType.POST },
  { label: "쇼핑", value: BannerDisplayType.SHOPPING },
  { label: "스토어", value: BannerDisplayType.STORE },
  { label: "홈 - 오브젝트형", value: BannerDisplayType.HOME_OBJECT },
  { label: "홈 - 썸네일형", value: BannerDisplayType.HOME_THUMBNAIL },
  { label: "홈 - 버튼형", value: BannerDisplayType.HOME_BUTTON },
  // { label: "쇼핑 - 이미지형", value: BannerDisplayType.SHOPPING_IMAGE },
  { label: "상품상세 - 카드형", value: BannerDisplayType.PRODUCT_DETAIL_CARD },
  { label: "상품상세 - 띠배너형", value: BannerDisplayType.PRODUCT_DETAIL_BAND },
  { label: "스토리 - 카드배너형 - 상단", value: BannerDisplayType.STORY_CARD_TOP },
  { label: "스토리 - 카드배너형 - 하단", value: BannerDisplayType.STORY_CARD_BOTTOM },
  { label: "스토리 상세 - 카드배너형", value: BannerDisplayType.STORY_DETAIL_CARD },
  { label: "스토리 상세 - 카드배너형 - short", value: BannerDisplayType.STORY_DETAIL_CARD_SHORT },
  { label: "스토리 상세 - 카드배너형 - long", value: BannerDisplayType.STORY_DETAIL_CARD_LONG },
  { label: "스토리 상세 - 모바일 - 띠배너형", value: BannerDisplayType.STORY_DETAIL_MOBILE_BAND },
  { label: "콘텐츠 상세 - 띠배너형", value: BannerDisplayType.CONTENT_DETAIL_BAND },
]

import { DjangoListResponse, DjangoResponse } from "@runners/interface"
import api from "."
import {
  AdminEmailSendParams,
  AdminEmailSendPayload,
  AdminEmailSendResponse,
  AdminEmailTemplateListParams,
  AdminEmailTemplatePayload,
  AdminEmailTemplateResponse,
} from "./email.type"

export const emailApi = {
  /**
   * 이메일 template 생성
   */
  postCreateEmailTemplate: async (payload: AdminEmailTemplatePayload) => {
    const { data } = await api.post(`/admin/sends/email-template`, payload)

    return data
  },

  /**
   * 이메일 template 리스트
   */
  getEmailTemplateList: async (params: AdminEmailTemplateListParams) => {
    const { data } = await api.get<DjangoListResponse<AdminEmailTemplateResponse>>(`/admin/sends/email-templates`, {
      params,
    })

    return data
  },

  /**
   * 이메일 template 상세
   */
  getEmailTemplateDetail: async (id: number) => {
    const { data } = await api.get<DjangoResponse<AdminEmailTemplateResponse>>(`/admin/sends/email-template/${id}`)

    return data
  },

  /**
   * 이메일 template 삭제
   */
  deleteEmailTemplate: async (idList: number[]) => {
    const { data } = await api.delete(`/admin/sends/email-templates`, {
      data: idList,
    })

    return data
  },

  /**
   * 이메일 template 수정
   */
  patchEmailTemplate: async (props: { id: number | string; payload: AdminEmailTemplatePayload }) => {
    const { id, payload } = props
    const { data } = await api.patch(`/admin/sends/email-template/${id}`, payload)

    return data
  },

  /**
   * 보낸 이메일 리스트
   */
  getSendEmailList: async (params: AdminEmailSendParams) => {
    const { data } = await api.get<DjangoListResponse<AdminEmailSendResponse>>(`/admin/sends/emails`, {
      params,
    })

    return data
  },

  /**
   * 이메일 보내기
   */
  postSendEmail: async (payload: AdminEmailSendPayload) => {
    const { data } = await api.post(`/admin/sends/emails`, payload)

    return data
  },
}

import { useFormContext, useWatch } from "react-hook-form"
import { Button, Input } from "@runners/ui"
import AdminFormField from "@/_components/admin-form-field"
import AdminHomeDefaultFields from "./admin-home-default-fields"
import usePreviewModal from "../../../_hooks/use-preview-modal"
import ColorPickerLabelButton from "../../color-picker-label-button"
import HomeButtonTypeBanner from "../../preview/home/home-button-type-banner"
import ShouldUnregisterThumbnailInput from "../../should-unregister-thumbnail-input"

const AdminHomeButtonFields = () => {
  const { control, register, setValue } = useFormContext()
  const backgroundColor = useWatch({ control, name: "button_background_color" })
  const textColor = useWatch({ control, name: "button_title_color" })
  const buttonTitle = useWatch({ control, name: "button_title" })

  const { openModal } = usePreviewModal({
    Component: HomeButtonTypeBanner,
    key: "admin-home-button-preview",
  })

  return (
    <>
      <AdminHomeDefaultFields />
      <AdminFormField label="버튼 텍스트">
        <Input {...register("button_title")} className="min-w-[400px]" />
      </AdminFormField>
      <div className="flex flex-row items-start justify-start gap-4">
        <ColorPickerLabelButton label="버튼 텍스트 색상 선택" name="button_title_color" />
        <ColorPickerLabelButton label="버튼 배경 색상 선택" name="button_background_color" />
      </div>

      <div>
        {buttonTitle && (
          <button
            type="button"
            style={{ backgroundColor, color: textColor }}
            className="rounded-full border-2 px-4 py-2.5 text-14 font-bold"
          >
            {buttonTitle}
          </button>
        )}
      </div>

      <ShouldUnregisterThumbnailInput
        name="thumbnail_image_url"
        label="웹 이미지"
        key="web-image"
        className="h-[240px] w-[1024px]"
      />
      <ShouldUnregisterThumbnailInput
        name="mobile_thumbnail_image_url"
        label="모바일 이미지"
        key="web-image"
        className="h-[240px] w-[335px]"
      />
      <div>
        <Button size="40" onClick={openModal}>
          미리보기
        </Button>
      </div>
    </>
  )
}

export default AdminHomeButtonFields

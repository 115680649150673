import dayjs from "dayjs"
import { AdminProductGroupListDto } from "@/_api/product-group.type"
import Image from "@/_components/image"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableActivateCurry } from "@/_components/table/table-activate"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { navigate } from "@/_constants/navigate"
import { useGetProductGroupListQuery } from "./_hooks/use-get-query"

const AdminProductGroupPage = () => {
  const { Table, page, pageSize } = usePaginationTable<AdminProductGroupListDto>()
  // const { SearchInput, search } = useTableSearch()

  const params = {
    page,
    page_size: pageSize,
    // search,
  }
  const { data: productGroupList, totalCount, isLoading } = useGetProductGroupListQuery(params)

  const dropdownList: TableDropdownList = []

  return (
    <AdminPageLayout totalCount={totalCount} create>
      {/* <SearchInput
        className="max-w-[500px]"
        label="검색 필터"
        description="가능한 필드: 몰?루"
        placeholder="필터링할 검색어 입력"
      /> */}
      <Table
        totalCount={totalCount}
        isLoading={isLoading}
        data={productGroupList}
        tableDropdownList={dropdownList}
        href={value => navigate.productGroupDetail(value.id)}
        title={["ID", "상태", "이미지", "그룹 이름", "서브 타이틀", "추천 상품", "수정일"]}
        widths={[1, 2, 2, 2, 2, 5, 2]}
        values={{
          ID: v => v.id,
          상태: TableActivateCurry(),
          이미지: v => <Image src={v.thumbnail_image_url} size="768" className="size-12 rounded-full" />,
          "그룹 이름": v => v.name,
          "서브 타이틀": v => v.sub_title,
          "추천 상품": v => v.product_titles,
          수정일: v => dayjs(v.modified).format("YYYY.MM.DD"),
        }}
      />
    </AdminPageLayout>
  )
}

export default AdminProductGroupPage

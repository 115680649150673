import { useCallback, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"

interface UseQueryPaginationOptions {
  page?: number
  pageSize?: number
  filterOption?: string
}

export const useQueryPagination = ({
  page = 1,
  pageSize = 10,
  filterOption = "전체보기",
}: UseQueryPaginationOptions = {}) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    let shouldUpdate = false

    if (!searchParams.has("page")) {
      searchParams.set("page", page.toString())
      shouldUpdate = true
    }

    if (!searchParams.has("page_size")) {
      searchParams.set("page_size", pageSize.toString())
      shouldUpdate = true
    }
    if (filterOption && filterOption !== "전체보기") {
      if (!searchParams.has("filter_option")) {
        searchParams.set("filter_option", filterOption)
        shouldUpdate = true
      }
    } else {
      searchParams.delete("filter_option")
    }
    if (shouldUpdate) {
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    }
  }, [navigate, location, page, pageSize])

  const setPage = useCallback(
    (newPage: number) => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set("page", newPage.toString())
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    },
    [navigate, location],
  )

  const setPageSize = useCallback(
    (newPageSize: number) => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set("page_size", newPageSize.toString())
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    },
    [navigate, location],
  )
  const setFilterOption = useCallback(
    (newFilterOption: string) => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set("filter_option", newFilterOption)
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    },
    [navigate, location],
  )
  return { setPage, setPageSize, setFilterOption }
}

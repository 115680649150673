import { useFormContext } from "react-hook-form"
import { Button, Input } from "@runners/ui"
import AdminFormField from "@/_components/admin-form-field"
import AdminHomeDefaultFields from "./admin-home-default-fields"
import usePreviewModal from "../../../_hooks/use-preview-modal"
import HomeThumbnailTypeBanner from "../../preview/home/home-thumbnail-type-banner"
import ShouldUnregisterThumbnailInput from "../../should-unregister-thumbnail-input"

const AdminHomeThumnailFields = () => {
  const { register } = useFormContext()

  const { openModal } = usePreviewModal({
    Component: HomeThumbnailTypeBanner,
    key: "admin-home-thumnail-preview",
  })

  return (
    <>
      <AdminFormField label="라벨" required>
        <Input className="min-w-[400px]" {...register("label_title")} />
      </AdminFormField>
      <AdminHomeDefaultFields />
      <ShouldUnregisterThumbnailInput
        name="thumbnail_image_url"
        label="웹 이미지"
        key="web-image"
        className="h-[416px] w-[624px]"
      />
      <ShouldUnregisterThumbnailInput
        name="mobile_thumbnail_image_url"
        label="모바일 이미지"
        key="mobile-image"
        className="h-[223px] w-[335px]"
      />
      <div>
        <Button size="48" onClick={openModal}>
          미리보기
        </Button>
      </div>
    </>
  )
}

export default AdminHomeThumnailFields
